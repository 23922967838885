.loader-bg {
  align-items: center;
  background: #132b47;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.loader {
  display: none;
  animation: spin 2s linear infinite;
  z-index: 2;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.center {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
